<template>
  <div class="w-full flex flex-col vh-80 flex">
    <div class="flex text-xl font-bold justify-center items-center">{{ detail.title }}</div>
    <YoutubeIframe
      :youtube-id="youtubeId"
    />
    <div class="text-base" v-html="detail.info">
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import { gunsaImpression } from '@/utils/trackers'

const YoutubeIframe = defineAsyncComponent(() => import('@/components/common/YoutubeIframe.vue'))

export default {
  name: 'Worship',
  components: {
    YoutubeIframe
  },
  created () {
    this.fetch({
      sequence: this.sequence,
      success: this.success
    })
  },
  computed: {
    ...mapState('worship', ['detail']),
    sequence () {
      return this.$route.params.sequence
    },
    youtubeId () {
      return this.detail?.youtubeVideoId ?? null
    }
  },
  methods: {
    ...mapActions('worship', ['fetch']),
    success () {
      if (!this.youtubeId) {
        this.$router.go(-1)
      }
      gunsaImpression({ page: 'IbadahUmum', title: `Ibadah Umum ${this.sequence}`, value: `www.gkigunsa.com/ibadahumum/${this.sequence}` })
    }
  }
}
</script>

<style lang="scss" scoped>
.vh-80 {
  height: 80vh;
}
</style>
